import { formatJstDate } from '../../utils'
import { useAuthProvider } from '../useAuthProvider'
import { usePublication } from '../usePublication'
import { useReservationApi } from '../useReservationApi'
import { useReservationState } from '../useReservationState'

export const useRegistrationCompleteDataLayerObject = () => {
  const { token } = useAuthProvider()
  const { reservationState } = useReservationState()
  const { reservation } = useReservationApi({
    id: reservationState.reservationId,
    token,
  })
  const hotelPublication = usePublication(
    'ja',
    reservation?.transaction?.input?.bookingEngineHotel?.publications
  )
  const roomPublication = usePublication(
    'ja',
    reservation?.transaction?.input?.bookingEngineRoom?.publications
  )
  const planPublication = usePublication(
    'ja',
    reservation?.transaction?.input?.bookingEnginePlan?.publications
  )
  const optionPublications = reservation?.transaction?.input?.options?.flatMap(
    (option) => [
      ...(option.option?.publications
        ?.map((publication) => ({
          ...publication,
          price: option.salesPrice ?? 0,
        }))
        .filter((publication) => publication.locale?.toLowerCase() === 'ja') ??
        []),
    ]
  )
  const optionItems =
    optionPublications?.map((optionPublication) => ({
      item_id: optionPublication.id,
      item_name: optionPublication.name,
      price: optionPublication.price,
      booking_type: 'option',
    })) ?? []

  if (!reservation) {
    return null
  }

  return {
    event: 'purchase',
    ecommerce: {
      transaction_id: reservation.transaction?.id,
      value: reservation.transaction?.input?.totalPrice,
      currency: 'JPY',
      items: [
        {
          item_id: hotelPublication?.id,
          item_name: hotelPublication?.name,
          item_brand: roomPublication?.name,
          item_category: planPublication?.name,
          check_in: formatJstDate(
            reservation?.transaction?.input?.checkInDate ?? '',
            'yyyy/MM/dd',
            'ja'
          ),
          check_out: formatJstDate(
            reservation?.transaction?.input?.checkOutDate ?? '',
            'yyyy/MM/dd',
            'ja'
          ),
          rooms: reservation?.transaction?.input?.roomCount,
          adult: reservation?.transaction?.input?.numberOfAdults,
          child: reservation?.transaction?.input?.numberOfChildren,
          infant: reservation?.transaction?.input?.numberOfInfants,
          price:
            (reservation?.transaction?.input?.roomPrice ?? 0) +
            (reservation?.transaction?.input?.discountPrice ?? 0),
          quantity: 1,
          booking_type: 'hotel',
        },
        ...optionItems,
      ],
    },
  }
}
