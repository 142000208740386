import React from 'react'
import { Helmet } from 'react-helmet-async'

import { EcommerceHead } from './EcommerceHead'

import {
  usePropertyProvider,
  useRegistrationCompleteDataLayerObject,
} from '../../../hooks'

export const RegistrationCompletionHead = () => {
  const { isHotel } = usePropertyProvider()
  const dataLayerObject = useRegistrationCompleteDataLayerObject()

  if (isHotel) {
    return (
      <React.Fragment>
        <Helmet
          script={[
            {
              type: 'text/javascript',
              innerHTML: `
              dataLayer.push({'event': 'cv-hotel'});
            `,
            },
          ]}
        />
        {dataLayerObject && <EcommerceHead dataLayerObject={dataLayerObject} />}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: `
            dataLayer.push({'event': 'cv-spa'});
          `,
          },
        ]}
      />
      {dataLayerObject && <EcommerceHead dataLayerObject={dataLayerObject} />}
    </React.Fragment>
  )
}
