import { EcommerceHead } from './EcommerceHead'

import { useRegistrationNewDataLayerObject } from '../../../hooks'

export const RegistrationNewHead = ({
  transactionId,
}: {
  transactionId?: number
}) => {
  const dataLayerObject = useRegistrationNewDataLayerObject(transactionId)

  if (!dataLayerObject) {
    return null
  }

  return <EcommerceHead dataLayerObject={dataLayerObject} />
}
