import { EcommerceHead } from './EcommerceHead'

import { useRegistrationConfirmDataLayerObject } from '../../../hooks'

export const RegistrationConfirmationHead = ({
  transactionId,
}: {
  transactionId: number
}) => {
  const dataLayerObject = useRegistrationConfirmDataLayerObject(transactionId)

  if (!dataLayerObject) {
    return null
  }

  return <EcommerceHead dataLayerObject={dataLayerObject} />
}
