import { usePropertyProvider } from '../usePropertyProvider'
import { usePublication } from '../usePublication'
import { useRoomApi } from '../useRoomApi'
import { useRouteParams } from '../useRouteParams'

export const usePlanDataLayerObject = () => {
  const { publications, id } = usePropertyProvider()
  const { property: propertySlug, room: roomSlug } = useRouteParams()
  const { data: roomResponse } = useRoomApi({
    property: propertySlug,
    room: roomSlug,
  })
  const publication = usePublication('ja', publications)
  const roomPublication = usePublication('ja', roomResponse?.publications)

  if (!roomPublication) {
    return null
  }

  return {
    event: 'select_item',
    ecommerce: {
      items: [
        {
          item_id: id,
          item_name: publication?.name,
          item_brand: roomPublication?.name,
          booking_type: 'hotel',
        },
      ],
    },
  }
}
