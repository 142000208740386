import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Registration } from '../../../../schemas'
import { Col, Container, ErrorMessage, FormInput } from '../../../basics'
import { FormRow } from '../FormRow'

/**
 * Invitation Code for ice-park is a "Request form" that is not used
 * by ice-park or other actives.
 * The invitation Code value is set to specialServiceRequest and sent
 * to the back-end.
 */
export const CodeForm = () => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext<Registration>()
  return (
    <Container>
      <FormRow>
        <Col pc={6}>
          <FormInput
            control={control}
            name="specialServiceRequest"
            inputMode="text"
            label={t(`components.CodeForm.label`)}
            helpMessage={t(`components.CodeForm.help`)}
          />
          <ErrorMessage errors={errors} name="specialServiceRequest" />
        </Col>
      </FormRow>
    </Container>
  )
}
