import { usePlanApi } from '../usePlanApi'
import { usePropertyProvider } from '../usePropertyProvider'
import { usePublication } from '../usePublication'
import { useRoomApi } from '../useRoomApi'
import { useRouteParams } from '../useRouteParams'

export const useOptionDataLayerObject = () => {
  const { publications, id } = usePropertyProvider()
  const publication = usePublication('ja', publications)
  const {
    property: propertySlug,
    room: roomSlug,
    plan: planSlug,
  } = useRouteParams()
  const { data: roomResponse } = useRoomApi({
    property: propertySlug,
    room: roomSlug,
  })
  const { data: planResponse } = usePlanApi({
    property: propertySlug,
    room: roomSlug,
    plan: planSlug,
  })
  const roomPublication = usePublication('ja', roomResponse?.publications)
  const planPublication = usePublication('ja', planResponse?.publications)

  if (!planPublication) {
    return null
  }

  return {
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          item_id: id,
          item_name: publication?.name,
          item_brand: roomPublication?.name,
          item_category: planPublication?.name,
          booking_type: 'hotel',
        },
      ],
    },
  }
}
