import { usePropertyProvider } from '../usePropertyProvider'
import { usePublication } from '../usePublication'

export const useRoomDataLayerObject = () => {
  const { publications, id } = usePropertyProvider()
  const publication = usePublication('ja', publications)

  if (!publication) {
    return null
  }

  return {
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_id: id,
          item_name: publication?.name,
          booking_type: 'hotel',
        },
      ],
    },
  }
}
