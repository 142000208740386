import { Helmet } from 'react-helmet-async'

export const EcommerceHead = ({
  dataLayerObject,
}: {
  dataLayerObject: {
    event: string
    ecommerce: {
      transaction_id?: number | undefined
      value?: number | undefined
      currency?: string
      items: { [key in string]: string | number | undefined }[]
    }
  }
}) => (
  <Helmet
    script={[
      {
        type: 'text/javascript',
        innerHTML: `
              dataLayer.push({ecommerce: null});
              dataLayer.push(
                ${JSON.stringify(dataLayerObject)}
              );
            `,
      },
    ]}
  />
)
