import { formatJstDate } from '../../utils'
import { useAuthProvider } from '../useAuthProvider'
import { usePublication } from '../usePublication'
import { useTransactionApi } from '../useTransactionApi'

export const useRegistrationConfirmDataLayerObject = (
  transactionId?: number
) => {
  const { token } = useAuthProvider()
  const { data: transaction } = useTransactionApi(transactionId, token)
  const hotelPublication = usePublication(
    'ja',
    transaction?.input?.bookingEngineHotel?.publications
  )
  const roomPublication = usePublication(
    'ja',
    transaction?.input?.bookingEngineRoom?.publications
  )
  const planPublication = usePublication(
    'ja',
    transaction?.input?.bookingEnginePlan?.publications
  )
  const optionPublications = transaction?.input?.options?.flatMap((option) => [
    ...(option.option?.publications
      ?.map((publication) => ({
        ...publication,
        price: option.salesPrice ?? 0,
      }))
      .filter((publication) => publication.locale?.toLowerCase() === 'ja') ??
      []),
  ])
  const optionItems =
    optionPublications?.map((optionPublication) => ({
      item_id: optionPublication.id,
      item_name: optionPublication.name,
      price: optionPublication.price,
      booking_type: 'option',
    })) ?? []

  if (!transaction || !transaction.input?.totalPrice) {
    return null
  }

  return {
    event: 'add_payment_info',
    ecommerce: {
      transaction_id: transaction?.id,
      value: transaction?.input?.totalPrice,
      currency: 'JPY',
      items: [
        {
          item_id: hotelPublication?.id,
          item_name: hotelPublication?.name,
          item_brand: roomPublication?.name,
          item_category: planPublication?.name,
          check_in: formatJstDate(
            transaction?.input?.checkInDate ?? '',
            'yyyy/MM/dd',
            'ja'
          ),
          check_out: formatJstDate(
            transaction?.input?.checkOutDate ?? '',
            'yyyy/MM/dd',
            'ja'
          ),
          rooms: transaction?.input?.roomCount,
          adult: transaction?.input?.numberOfAdults,
          child: transaction?.input?.numberOfChildren,
          infant: transaction?.input?.numberOfInfants,
          booking_type: 'hotel',
        },
        ...optionItems,
      ],
    },
  }
}
